// 实名认证
import service from '../request/index'

const baseUrl = window.globalUrl.HOME_API;

// 获取短信验证码
export const getSmsCode = (params) => {
  return service({
    url: `${baseUrl}/admin/mobile/sendsms/${params}`,
    method: 'get',
  })
}

// 个人认证
export const certificateByCard = (params) => {
  return service({
    url: `${baseUrl}/admin/userauth/setauthinfo`,
    method: 'post',
    data: params
  })
}

// 上传个人认证图片
export const upLoadCardImages = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjyService/upLoadCardImages`,
    method: 'post',
    data: params,
  })
}

// 企业认证
export const certificateByLisence = (params) => {
  return service({
    url: `${baseUrl}/admin/companyauth/setauthinfo`,
    method: 'post',
    data: params
  })
}

// 上传企业认证图片
export const upLoadCompanyImages = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjyService/upLoadCompanyImages`,
    method: 'post',
    data: params,
  })
}

// 用户修改手机号
export const modifyTel = (params) => {
  return service({
    url: `${baseUrl}/admin/user/modifyphone`,
    method: 'put',
    params: params
  })
}

// 用户修改资料
export const modifyInfo = (params) => {
  return service({
    url: `${baseUrl}/admin/user/modifyinfo`,
    method: 'post',
    data: params
  })
}

// 获取用户信息
export const getInfo = (params) => {
  return service({
    url: `${baseUrl}/admin/user/selfinfo/${params}`,
    method: 'get',
  })
}

// 个人中心首页信息 是否实名认证认证
export const getMemberInfo = (params) => {
  return service({
    url: `${baseUrl}/admin/user/userinfo/${params}`,
    method: 'get',
  })
}

// 认证状态进行判断
export const getCertificateStatus = (params) => {
  return service({
    url: `${baseUrl}/admin/user/authsta/${params}`,
    method: 'get',
  })
}

// 修改密码
export const resetPwd = (params) => {
  return service({
    url: `${baseUrl}/admin/user/modifypwd`,
    method: 'post',
    data: params,
    // isForm: true
  })
}

